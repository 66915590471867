import React, {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useRef,
} from 'react'
import { GestureResponderEvent } from 'react-native'
import { useFragment, graphql } from 'react-relay'

import { useLocation, useNavigate } from 'react-router-dom'
import {
  Select,
  useSelectItemParentContext,
  useSelectContext,
  ViewProps,
  composeRefs,
} from 'tamagui'

import { useMutation } from '../../data/useMutation'

import {
  StyledSelect,
  StyledSelectContent,
  StyledSelectItem,
  StyledSelectItemText,
  StyledSelectViewport,
} from '../Form/FormSelect'

import { Paragraph } from '../Text'

import { EventAttendButtonButton } from './EventAttendButtonButton'

import { EventAttendButtonNew_event$key } from './__generated__/EventAttendButtonNew_event.graphql'
import {
  EventAttendButtonNew_query$data,
  EventAttendButtonNew_query$key,
} from './__generated__/EventAttendButtonNew_query.graphql'

import { EventAttendButtonSingleAttend_event$key } from './__generated__/EventAttendButtonSingleAttend_event.graphql'
import { EventAttendButtonHybridAttend_event$key } from './__generated__/EventAttendButtonHybridAttend_event.graphql'

import { EventAttendButtonSingleAttendMutation } from './__generated__/EventAttendButtonSingleAttendMutation.graphql'
import { EventAttendButtonSingleRemoveAttendanceMutation } from './__generated__/EventAttendButtonSingleRemoveAttendanceMutation.graphql'

import {
  EventAttendButtonHybridAttendMutation,
  EventAttendeeStatus,
} from './__generated__/EventAttendButtonHybridAttendMutation.graphql'
import { EventAttendButtonHybridRemoveAttendanceMutation } from './__generated__/EventAttendButtonHybridRemoveAttendanceMutation.graphql'

import { EventAttendButtonSelectTriggerButton_event$key } from './__generated__/EventAttendButtonSelectTriggerButton_event.graphql'

import '../Button/Button.css'

export const EventAttendButton = forwardRef<
  unknown,
  {
    event: EventAttendButtonNew_event$key
    query: EventAttendButtonNew_query$key
    onOpenChange?: (open: boolean) => any
  } & ViewProps
>(({ event, query, onOpenChange, ...props }, ref) => {
  const data = useFragment(
    graphql`
      fragment EventAttendButtonNew_event on Event {
        rowId
        prettyUrl

        endAt

        hasExternalUrl
        hasVenue

        myAttendance {
          status
          userId

          ticketPurchase {
            stripePaymentIntentStatus
          }
        }

        owner {
          __typename
          ... on Guild {
            slugId

            myMembership {
              membershipLevel
            }
          }
        }

        ...EventAttendButtonButton_event

        ...EventAttendButtonSingleAttend_event
        ...EventAttendButtonHybridAttend_event
      }
    `,
    event
  )

  const queryData = useFragment(
    graphql`
      fragment EventAttendButtonNew_query on Query {
        viewer {
          id
        }
      }
    `,
    query
  )

  const navigate = useNavigate()
  const location = useLocation()

  const eventInPast = useMemo(() => {
    if (!data.endAt) {
      return false
    }

    return new Date().getTime() > new Date(data.endAt as string).getTime()
  }, [data.endAt])

  if (eventInPast) {
    if (
      !queryData.viewer ||
      (data.owner?.__typename === 'Guild' && !data.owner.myMembership)
    ) {
      return (
        <EventAttendButtonButton
          ref={ref}
          successFlash={false}
          errorFlash={false}
          event={data}
          onPress={(event) => {
            if (data.owner?.__typename === 'Guild') {
              event.preventDefault()
              event.stopPropagation()

              const slugId = data.owner.slugId

              const guildConfirmURL = `/${slugId}/confirm`

              if (!queryData.viewer) {
                navigate('/sign-up', {
                  state: {
                    backgroundLocation:
                      location.state?.backgroundLocation || location,
                    authRedirectPath: guildConfirmURL,
                  },
                })
              } else {
                navigate(guildConfirmURL)
              }
            }
          }}
        />
      )
    }

    return (
      <EventAttendButtonButton
        ref={ref}
        event={data}
        successFlash={false}
        errorFlash={false}
      />
    )
  }

  if (data.hasExternalUrl && data.hasVenue) {
    return (
      <HybridAttendButton
        {...props}
        queryData={queryData}
        event={data}
        ref={ref}
        onOpenChange={onOpenChange}
      />
    )
  }

  if (!data.hasExternalUrl && !data.hasVenue) {
    return (
      <Paragraph ref={ref} size='$4' fontWeight='$5' color='$errorColor'>
        Missing Venue or Online URL
      </Paragraph>
    )
  }

  return (
    <SingleAttendButton
      {...props}
      queryData={queryData}
      event={data}
      ref={ref}
      onOpenChange={onOpenChange}
    />
  )
})

const SingleAttendButton = forwardRef(
  (
    {
      event,
      onOpenChange,
      queryData,
      ...props
    }: {
      event: EventAttendButtonSingleAttend_event$key
      queryData: EventAttendButtonNew_query$data
      onOpenChange?: (open: boolean) => any
    },
    ref
  ) => {
    const data = useFragment(
      graphql`
        fragment EventAttendButtonSingleAttend_event on Event {
          rowId
          prettyUrl

          hasVenue
          hasExternalUrl

          myAttendance {
            status

            ticketPurchase {
              status
            }
          }

          owner {
            __typename

            ... on Guild {
              myMembership {
                membershipLevel
              }
            }
          }

          eventTicketingTiers {
            totalCount
          }

          ...EventAttendButtonButton_event
          ...EventAttendButtonSelectTriggerButton_event
        }
      `,
      event
    )

    const eventAttend =
      useMutation<EventAttendButtonSingleAttendMutation>(graphql`
        mutation EventAttendButtonSingleAttendMutation(
          $input: AttendEventInput!
        ) {
          attendEvent(input: $input) {
            event {
              ...EventItemNew_event

              ...EventScreenNewMobile_event
              ...EventScreenNewDesktop_event
              ...EventScreenAttendeesDialogContent_event
            }
          }
        }
      `)

    const removeEventAttendance =
      useMutation<EventAttendButtonSingleRemoveAttendanceMutation>(graphql`
        mutation EventAttendButtonSingleRemoveAttendanceMutation(
          $input: RemoveEventAttendanceInput!
        ) {
          removeEventAttendance(input: $input) {
            event {
              ...EventItemNew_event

              ...EventScreenNewMobile_event
              ...EventScreenNewDesktop_event
              ...EventScreenAttendeesDialogContent_event
            }
          }
        }
      `)

    const navigate = useNavigate()
    const location = useLocation()

    const [buttonForcedState, setButtonForcedState] = useState<
      'pressed' | 'success' | 'error' | 'notPressed'
    >('notPressed')

    const onPress = useCallback(
      async (event: GestureResponderEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (
          data.eventTicketingTiers.totalCount > 0 &&
          data.myAttendance?.ticketPurchase?.status === 'SUCCEEDED'
        ) {
          return
        }

        setButtonForcedState('pressed')

        try {
          if (
            !queryData.viewer ||
            (data.owner?.__typename === 'Guild' && !data.owner.myMembership) ||
            data.owner?.__typename !== 'Guild' ||
            data.eventTicketingTiers.totalCount > 0
          ) {
            const status = data.hasVenue
              ? 'ATTENDING_IN_PERSON'
              : 'ATTENDING_VIRTUAL'

            const eventAttendURL = `/events/${data.prettyUrl}/attend?status=${status}`

            if (!queryData.viewer) {
              navigate('/sign-up', {
                state: {
                  backgroundLocation:
                    location.state?.backgroundLocation || location,
                  authRedirectPath: eventAttendURL,
                },
              })
            } else {
              navigate(eventAttendURL, {
                state: {
                  backgroundLocation:
                    location.state?.backgroundLocation || location,
                },
              })
            }
          } else if (
            !data.myAttendance ||
            data.myAttendance.status === 'NOT_ATTENDING'
          ) {
            await eventAttend({
              variables: {
                input: {
                  eventId: data.rowId,
                  status: data.hasVenue
                    ? 'ATTENDING_IN_PERSON'
                    : 'ATTENDING_VIRTUAL',
                },
              },
            })

            setButtonForcedState('success')

            await new Promise<void>((resolve) =>
              setTimeout(() => resolve(), 2000)
            )
          }
        } catch (err) {
          setButtonForcedState('error')

          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 2000)
          )
        }

        setButtonForcedState('notPressed')
      },
      [
        queryData,
        eventAttend,
        removeEventAttendance,
        data,
        setButtonForcedState,
      ]
    )

    if (
      data.eventTicketingTiers.totalCount > 0 ||
      !data.myAttendance ||
      ['NOT_ATTENDING'].includes(data.myAttendance.status)
    ) {
      return (
        <EventAttendButtonButton
          event={data}
          forceState={buttonForcedState}
          onPress={onPress}
        />
      )
    }

    return (
      <StyledSelect
        // ref={(ref) => {
        //   if (!ref) {
        //     return
        //   }

        //   ref.ontouchstart = (e) => {
        //     e.preventDefault()
        //   }
        // }}
        onOpenChange={onOpenChange}
        onValueChange={async (value: EventAttendeeStatus) => {
          setButtonForcedState('pressed')

          try {
            if (value === 'NOT_ATTENDING') {
              await removeEventAttendance({
                variables: {
                  input: {
                    eventId: data.rowId,
                  },
                },
              })

              setButtonForcedState('success')
            } else {
              setButtonForcedState('error')
            }
          } catch (err) {
            setButtonForcedState('error')
          } finally {
            await new Promise<void>((resolve) =>
              setTimeout(() => resolve(), 2000)
            )

            setButtonForcedState('notPressed')
          }
        }}
      >
        <SelectTriggerButton
          ref={ref}
          {...props}
          buttonForcedState={buttonForcedState}
          event={data}
        />

        <StyledSelectContent zIndex={200_000}>
          <StyledSelectViewport>
            <StyledSelectItem index={1} value='NOT_ATTENDING'>
              <StyledSelectItemText>Don't Attend</StyledSelectItemText>
            </StyledSelectItem>
          </StyledSelectViewport>
        </StyledSelectContent>
      </StyledSelect>
    )
  }
)

const HybridAttendButton = forwardRef<
  unknown,
  {
    event: EventAttendButtonHybridAttend_event$key
    queryData: EventAttendButtonNew_query$data
    onOpenChange?: (open: boolean) => any
  }
>(({ event, onOpenChange, queryData, ...props }, ref) => {
  const data = useFragment(
    graphql`
      fragment EventAttendButtonHybridAttend_event on Event {
        rowId
        prettyUrl

        hasVenue
        hasExternalUrl

        myAttendance {
          status
        }

        owner {
          __typename

          ... on Guild {
            myMembership {
              membershipLevel
            }
          }
        }

        eventTicketingTiers {
          totalCount
        }

        ...EventAttendButtonSelectTriggerButton_event
      }
    `,
    event
  )

  const eventAttend =
    useMutation<EventAttendButtonHybridAttendMutation>(graphql`
      mutation EventAttendButtonHybridAttendMutation(
        $input: AttendEventInput!
      ) {
        attendEvent(input: $input) {
          event {
            ...EventItemNew_event
          }
        }
      }
    `)

  const removeEventAttendance =
    useMutation<EventAttendButtonHybridRemoveAttendanceMutation>(graphql`
      mutation EventAttendButtonHybridRemoveAttendanceMutation(
        $input: RemoveEventAttendanceInput!
      ) {
        removeEventAttendance(input: $input) {
          event {
            ...EventItemNew_event
          }
        }
      }
    `)

  const navigate = useNavigate()
  const location = useLocation()

  const [buttonForcedState, setButtonForcedState] = useState<
    'pressed' | 'success' | 'error' | 'notPressed'
  >('notPressed')

  const selectOptions = useMemo(() => {
    if (!data.myAttendance || data.myAttendance?.status === 'NOT_ATTENDING') {
      return (
        <>
          <StyledSelectItem index={1} value='ATTENDING_IN_PERSON'>
            <StyledSelectItemText>Attend In-Person</StyledSelectItemText>
          </StyledSelectItem>

          <StyledSelectItem index={2} value='ATTENDING_VIRTUAL'>
            <StyledSelectItemText>Attend Online</StyledSelectItemText>
          </StyledSelectItem>
        </>
      )
    }

    if (
      ['ATTENDING_IN_PERSON', 'WAITING_IN_PERSON'].includes(
        data.myAttendance.status
      )
    ) {
      return (
        <>
          <StyledSelectItem index={1} value='ATTENDING_VIRTUAL'>
            <StyledSelectItemText>Attend Online</StyledSelectItemText>
          </StyledSelectItem>

          <StyledSelectItem index={2} value='NOT_ATTENDING'>
            <StyledSelectItemText>Don't Attend</StyledSelectItemText>
          </StyledSelectItem>
        </>
      )
    }

    if (
      ['ATTENDING_VIRTUAL', 'WAITING_VIRTUAL'].includes(
        data.myAttendance.status
      )
    ) {
      return (
        <>
          <StyledSelectItem index={1} value='ATTENDING_IN_PERSON'>
            <StyledSelectItemText>Attend In-Person</StyledSelectItemText>
          </StyledSelectItem>

          <StyledSelectItem index={2} value='NOT_ATTENDING'>
            <StyledSelectItemText>Don't Attend</StyledSelectItemText>
          </StyledSelectItem>
        </>
      )
    }

    return (
      <StyledSelectItem index={1} value='NOT_ATTENDING'>
        <StyledSelectItemText>Don't Attend</StyledSelectItemText>
      </StyledSelectItem>
    )
  }, [data])

  return (
    <StyledSelect
      // ref={(ref) => {
      //   if (typeof ref !== 'object' || !ref) {
      //     return
      //   }

      //   ref.ontouchstart = (e) => {
      //     e.preventDefault()
      //   }
      // }}
      onOpenChange={onOpenChange}
      onValueChange={async (value: EventAttendeeStatus) => {
        setButtonForcedState('pressed')

        try {
          if (
            !queryData.viewer ||
            (data.owner?.__typename === 'Guild' && !data.owner.myMembership) ||
            data.owner?.__typename !== 'Guild' ||
            data.eventTicketingTiers.totalCount > 0
          ) {
            const eventAttendURL = `/events/${data.prettyUrl}/attend?status=${value}`

            if (!queryData.viewer) {
              navigate('/sign-up', {
                state: {
                  backgroundLocation:
                    location.state?.backgroundLocation || location,
                  authRedirectPath: eventAttendURL,
                },
              })
            } else {
              navigate(eventAttendURL, {
                state: {
                  backgroundLocation:
                    location.state?.backgroundLocation || location,
                },
              })
            }
          } else if (
            (!data.myAttendance ||
              data.myAttendance.status === 'NOT_ATTENDING') &&
            (value === 'ATTENDING_IN_PERSON' || value === 'ATTENDING_VIRTUAL')
          ) {
            await eventAttend({
              variables: {
                input: {
                  eventId: data.rowId,
                  status: value,
                },
              },
            })

            setButtonForcedState('success')

            await new Promise<void>((resolve) =>
              setTimeout(() => resolve(), 2000)
            )
          } else if (
            data.myAttendance &&
            [
              'ATTENDING_IN_PERSON',
              'ATTENDING_VIRTUAL',
              'WAITING_IN_PERSON',
              'WAITING_VIRTUAL',
            ].includes(data.myAttendance.status)
          ) {
            setButtonForcedState('pressed')

            if (value === 'NOT_ATTENDING') {
              await removeEventAttendance({
                variables: {
                  input: {
                    eventId: data.rowId,
                  },
                },
              })
            } else {
              await eventAttend({
                variables: {
                  input: {
                    eventId: data.rowId,
                    status: value,
                  },
                },
              })
            }

            setButtonForcedState('success')

            await new Promise<void>((resolve) =>
              setTimeout(() => resolve(), 2000)
            )
          }
        } catch (err) {
          setButtonForcedState('error')

          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 2000)
          )
        } finally {
          setButtonForcedState('notPressed')
        }
      }}
    >
      <SelectTriggerButton
        ref={ref}
        {...props}
        buttonForcedState={buttonForcedState}
        event={data}
      />

      <StyledSelectContent zIndex={200_000}>
        <StyledSelectViewport>{selectOptions}</StyledSelectViewport>
      </StyledSelectContent>
    </StyledSelect>
  )
})

const SelectTriggerButton = forwardRef(
  (
    {
      buttonForcedState,
      event,
      ...props
    }: {
      buttonForcedState: 'pressed' | 'success' | 'error' | 'notPressed'
      event: EventAttendButtonSelectTriggerButton_event$key
    },
    ref
  ) => {
    const data = useFragment(
      graphql`
        fragment EventAttendButtonSelectTriggerButton_event on Event {
          ...EventAttendButtonButton_event
        }
      `,
      event
    )

    const selectContext = useSelectContext('SelectTrigger', undefined)

    const buttonRef = useRef()

    const itemParentContext = useSelectItemParentContext(
      'SelectTrigger',
      undefined
    )

    const referenceProps = itemParentContext.interactions?.getReferenceProps()

    return (
      <EventAttendButtonButton
        ref={composeRefs(
          ref,
          selectContext.floatingContext?.refs.setReference,
          buttonRef
        )}
        {...props}
        {...referenceProps}
        forceState={buttonForcedState}
        event={data}
      />
    )
  }
)
