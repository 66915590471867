import React, { useMemo, useState } from 'react'
import { useFragment, graphql } from 'react-relay'

import {
  XStack,
  YStack,
  Tooltip,
  StackProps,
  getTokenValue,
  Token,
  styled,
} from 'tamagui'
import { UserPrimaryPhoto } from '../User/UserPrimaryPhoto'
import { Paragraph, Text } from '../Text'

import { EventAttendeesOverlap_eventAttendees$key } from './__generated__/EventAttendeesOverlap_eventAttendees.graphql'

import './EventAttendeesOverlap.css'

export const EventAttendeesOverlap = ({
  eventAttendees,
  attendingCount,
  waitingCount,
  size = '$6',
  limitAttendees,
  ...props
}: {
  eventAttendees: EventAttendeesOverlap_eventAttendees$key
  attendingCount: number
  waitingCount: number
  limitAttendees?: number
  size?: Token
} & StackProps) => {
  const data = useFragment(
    graphql`
      fragment EventAttendeesOverlap_eventAttendees on EventAttendeesConnection {
        edges {
          node {
            user {
              firstName
              lastName

              ...UserPrimaryPhotoNew_user
            }
          }

          cursor
        }
      }
    `,
    eventAttendees
  )

  const eventAttendeeSize = useMemo(() => {
    return getTokenValue(size, 'size')
  }, [size])

  const attendees = useMemo(() => {
    if (!limitAttendees) {
      return data.edges.toReversed()
    }

    return data.edges.slice(0, limitAttendees).toReversed()
  }, [data, limitAttendees])

  const [hoveredItem, setHoveredItem] = useState<null | number>(null)

  const totalAttendeeCount = attendingCount + waitingCount

  return (
    <XStack
      {...props}
      alignItems='center'
      onMouseLeave={() => setHoveredItem(null)}
    >
      {attendees.map((edge, index) => {
        if (!edge.node.user) {
          return null
        }

        return (
          <Tooltip
            open={hoveredItem === index}
            key={index}
            placement='top'
            delay={100}
          >
            <Tooltip.Trigger
              tag='dialog'
              className='EventAttendeesOverlap'
              marginLeft={
                index === 0
                  ? 0
                  : hoveredItem === index - 1
                  ? 0
                  : -(eventAttendeeSize / 3)
              }
              onMouseEnter={() => {
                setHoveredItem(index)
              }}
              onPress={() => {
                setHoveredItem(null)
              }}
            >
              <UserPrimaryPhoto size={size} user={edge.node.user} />
            </Tooltip.Trigger>

            <Tooltip.Content
              animation='fastSpring'
              enterStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
              exitStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
              scale={1}
              x={0}
              y={0}
              opacity={1}
              borderColor='rgba(105, 102, 118, 1.0)'
              backgroundColor='rgba(105, 102, 118, 0.8)'
              style={{
                backdropFilter: 'blur(8px) saturate(250%)',
                WebkitBackdropFilter: 'blur(8px) saturate(250%)',
              }}
            >
              <Tooltip.Arrow
                borderColor='rgba(105, 102, 118, 1.0)'
                backgroundColor='rgba(105, 102, 118, 0.7)'
                style={{
                  backdropFilter: 'blur(8px) saturate(250%)',
                  WebkitBackdropFilter: 'blur(8px) saturate(250%)',
                }}
              />

              <Paragraph size='$4' color='$grey0' fontWeight='$5'>
                {edge.node.user.firstName} {edge.node.user.lastName}
              </Paragraph>
            </Tooltip.Content>
          </Tooltip>
        )
      })}

      {totalAttendeeCount - attendees.length > 0 && (
        <Tooltip placement='top' delay={100}>
          <Tooltip.Trigger
            tag='dialog'
            className='EventAttendeesOverlap'
            marginLeft={
              hoveredItem === attendees.length - 1
                ? 0
                : -(eventAttendeeSize / 3)
            }
          >
            <AttendanceCountCircle
              height={size}
              minWidth={size}
              borderRadius={eventAttendeeSize / 2}
            >
              <Text
                size='$3'
                fontWeight='$7'
                color='$grey7'
                style={{
                  fontVariantNumeric: 'tabular-nums slashed-zero',
                  letterSpacing: -0.3,
                }}
              >
                {totalAttendeeCount >= 1000
                  ? `${Math.floor(totalAttendeeCount / 1000)}K`
                  : totalAttendeeCount}
              </Text>
            </AttendanceCountCircle>
          </Tooltip.Trigger>

          <Tooltip.Content
            animation='fastSpring'
            enterStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 5, opacity: 0, scale: 0.9 }}
            scale={1}
            x={0}
            y={0}
            opacity={1}
            borderColor='rgba(105, 102, 118, 1.0)'
            backgroundColor='rgba(105, 102, 118, 0.8)'
            style={{
              backdropFilter: 'blur(8px) saturate(250%)',
              WebkitBackdropFilter: 'blur(8px) saturate(250%)',
            }}
          >
            <Tooltip.Arrow
              borderColor='rgba(105, 102, 118, 1.0)'
              backgroundColor='rgba(105, 102, 118, 0.7)'
              style={{
                backdropFilter: 'blur(8px) saturate(250%)',
                WebkitBackdropFilter: 'blur(8px) saturate(250%)',
              }}
            />

            <YStack alignItems='center'>
              <Paragraph size='$4' color='$grey0' fontWeight='$5'>
                {attendingCount} Attending
              </Paragraph>

              {waitingCount > 0 && (
                <Paragraph size='$4' color='$grey0' fontWeight='$5'>
                  {waitingCount} Waiting
                </Paragraph>
              )}
            </YStack>
          </Tooltip.Content>
        </Tooltip>
      )}
    </XStack>
  )
}

const AttendanceCountCircle = styled(XStack, {
  backgroundColor: '$grey3',
  boxShadow: '0 0 0 2px #FFFFFF',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$1',
})
