/**
 * @generated SignedSource<<b3d8c4e1b6a01e57b9169b78f1c3946d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventAttendeeStatus = "ATTENDING_IN_PERSON" | "ATTENDING_VIRTUAL" | "NOT_ATTENDING" | "WAITING_IN_PERSON" | "WAITING_VIRTUAL" | "%future added value";
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventAttendButtonHybridAttend_event$data = {
  readonly eventTicketingTiers: {
    readonly totalCount: number;
  };
  readonly hasExternalUrl: boolean | null | undefined;
  readonly hasVenue: boolean | null | undefined;
  readonly myAttendance: {
    readonly status: EventAttendeeStatus;
  } | null | undefined;
  readonly owner: {
    readonly __typename: "Guild";
    readonly myMembership: {
      readonly membershipLevel: GuildMembershipLevel;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly prettyUrl: string;
  readonly rowId: String;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonSelectTriggerButton_event">;
  readonly " $fragmentType": "EventAttendButtonHybridAttend_event";
};
export type EventAttendButtonHybridAttend_event$key = {
  readonly " $data"?: EventAttendButtonHybridAttend_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonHybridAttend_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAttendButtonHybridAttend_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prettyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasVenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExternalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventAttendee",
      "kind": "LinkedField",
      "name": "myAttendance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GuildMembership",
              "kind": "LinkedField",
              "name": "myMembership",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "membershipLevel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Guild",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventTicketingTiersConnection",
      "kind": "LinkedField",
      "name": "eventTicketingTiers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonSelectTriggerButton_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "60450081064c7af5f49d6c393cffd817";

export default node;
