import React, { useMemo, useEffect, useState } from 'react'
import { useFragment, graphql } from 'react-relay'

import { ImageProps, getTokenValue, Token, styled } from 'tamagui'

import { Image } from '../Image'

import { IMAGEKIT_URL } from '../../data/config/url'

import { pickFromDefaults } from '../util'

import { UserPrimaryPhotoNew_user$key } from './__generated__/UserPrimaryPhotoNew_user.graphql'

const DEFAULT_AVATARS = [
  'default-avatar-1_5QE9rI7Ha3.png',
  'default-avatar-2_DT5LXnCTf.png',
  'default-avatar-3_NTdhIFeg2.png',
  'default-avatar-4_QrxDP2X-N.png',
]

const UserPrimaryPhotoFrame = styled(Image, {
  name: 'UserPrimaryPhoto',
  boxShadow: '0 0 0 2px #FFFFFF',
  borderColor: 'rgba(19, 0, 102, 0.16)',
  borderWidth: 1,
  backgroundColor: '$grey0',
})

export const UserPrimaryPhoto = React.forwardRef(
  (
    {
      user,
      size = '$5',
      ...props
    }: {
      user: UserPrimaryPhotoNew_user$key
      size: Token
    },
    ref
  ) => {
    const data = useFragment(
      graphql`
        fragment UserPrimaryPhotoNew_user on User {
          firstName
          lastName
          rowId
          emailMd5

          primaryPhoto {
            rowId
            contentType
          }
        }
      `,
      user
    )

    const parsedSize = useMemo(() => {
      return getTokenValue(size, 'size') as number
    }, [size])

    if (
      data.primaryPhoto &&
      data.primaryPhoto.rowId &&
      data.primaryPhoto.contentType
    ) {
      return (
        <UserPrimaryPhotoFrame
          {...props}
          alt={`Primary Photo for ${data.firstName} ${data.lastName}`}
          ref={ref}
          src={`${IMAGEKIT_URL}/tr:w-${parsedSize},h-${parsedSize},fo-auto,dpr-3,r-max/${
            data.primaryPhoto.rowId
          }.${data.primaryPhoto.contentType.toLowerCase()}`}
          width={parsedSize}
          height={parsedSize}
          borderRadius={size}
        />
      )
    }

    return (
      <GravatarPhotoWithFallback
        {...props}
        alt={`Primary Photo for ${data.firstName} ${data.lastName}`}
        ref={ref}
        emailMd5={data.emailMd5}
        width={parsedSize}
        height={parsedSize}
        borderRadius={size}
        fallback={
          <UserDefaultPhoto
            {...props}
            alt={`Primary Photo for ${data.firstName} ${data.lastName}`}
            ref={ref}
            parsedSize={parsedSize}
            rowId={data.rowId}
            width={parsedSize}
            height={parsedSize}
            borderRadius={size}
          />
        }
      />
    )
  }
)

const GravatarPhotoWithFallback = UserPrimaryPhotoFrame.styleable<
  {
    emailMd5: string | null
    fallback: React.ReactElement<any, any>
    width: number
    height: number
  } & Omit<ImageProps, 'source' | 'width' | 'height'>
>(({ emailMd5, fallback, ...props }, ref) => {
  const [hasGravatar, setHasGravatar] = useState<boolean | undefined>()

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `https://gravatar.com/avatar/${emailMd5}?d=404`
        )

        if (response.ok) {
          return setHasGravatar(true)
        }
      } catch (err) {}

      setHasGravatar(false)
    })()
  }, [emailMd5])

  if (hasGravatar) {
    if (props.width) {
      return (
        <UserPrimaryPhotoFrame
          {...props}
          ref={ref}
          src={`https://gravatar.com/avatar/${emailMd5}?s=${props.width * 3}`}
        />
      )
    }

    return (
      <UserPrimaryPhotoFrame
        {...props}
        ref={ref}
        src={`https://gravatar.com/avatar/${emailMd5}`}
      />
    )
  }

  return fallback
})

export const UserDefaultPhoto = UserPrimaryPhotoFrame.styleable<
  { rowId: string; parsedSize: number } & Omit<ImageProps, 'source'>
>(({ rowId, parsedSize, ...props }, ref) => {
  const defaultAvatar = useMemo(() => {
    const avatar = pickFromDefaults(rowId, DEFAULT_AVATARS)

    return `${IMAGEKIT_URL}/tr:w-${parsedSize},h-${parsedSize},fo-auto,dpr-3,r-max/${avatar}`
  }, [rowId, parsedSize])

  return <UserPrimaryPhotoFrame {...props} ref={ref} src={defaultAvatar} />
})
