/**
 * @generated SignedSource<<6390bfa785be1e2f62b9f8e8b107c3fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ImageContentType = "GIF" | "JPEG" | "PNG" | "WEBP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserPrimaryPhotoNew_user$data = {
  readonly emailMd5: string | null | undefined;
  readonly firstName: string;
  readonly lastName: string;
  readonly primaryPhoto: {
    readonly contentType: ImageContentType;
    readonly rowId: String;
  } | null | undefined;
  readonly rowId: String;
  readonly " $fragmentType": "UserPrimaryPhotoNew_user";
};
export type UserPrimaryPhotoNew_user$key = {
  readonly " $data"?: UserPrimaryPhotoNew_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhotoNew_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPrimaryPhotoNew_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailMd5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "primaryPhoto",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "2c34be1fb5a43ad44064fe1fee6c6f57";

export default node;
