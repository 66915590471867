/**
 * @generated SignedSource<<7b16f6e1285e99d781c6880e47396664>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventAttendeeStatus = "ATTENDING_IN_PERSON" | "ATTENDING_VIRTUAL" | "NOT_ATTENDING" | "WAITING_IN_PERSON" | "WAITING_VIRTUAL" | "%future added value";
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
export type StripePaymentIntentStatuses = "CANCELED" | "PROCESSING" | "REQUIRES_ACTION" | "REQUIRES_CAPTURE" | "REQUIRES_CONFIRMATION" | "REQUIRES_PAYMENT_METHOD" | "SUCCEEDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventAttendButtonNew_event$data = {
  readonly endAt: String;
  readonly hasExternalUrl: boolean | null | undefined;
  readonly hasVenue: boolean | null | undefined;
  readonly myAttendance: {
    readonly status: EventAttendeeStatus;
    readonly ticketPurchase: {
      readonly stripePaymentIntentStatus: StripePaymentIntentStatuses | null | undefined;
    } | null | undefined;
    readonly userId: String;
  } | null | undefined;
  readonly owner: {
    readonly __typename: "Guild";
    readonly myMembership: {
      readonly membershipLevel: GuildMembershipLevel;
    } | null | undefined;
    readonly slugId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly prettyUrl: string;
  readonly rowId: String;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonButton_event" | "EventAttendButtonHybridAttend_event" | "EventAttendButtonSingleAttend_event">;
  readonly " $fragmentType": "EventAttendButtonNew_event";
};
export type EventAttendButtonNew_event$key = {
  readonly " $data"?: EventAttendButtonNew_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonNew_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAttendButtonNew_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prettyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExternalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasVenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventAttendee",
      "kind": "LinkedField",
      "name": "myAttendance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EventAttendeeTicketPurchase",
          "kind": "LinkedField",
          "name": "ticketPurchase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripePaymentIntentStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slugId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GuildMembership",
              "kind": "LinkedField",
              "name": "myMembership",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "membershipLevel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Guild",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonButton_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonSingleAttend_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonHybridAttend_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "8bdc33676e4a546f582847b132706b45";

export default node;
