/**
 * @generated SignedSource<<a33eee9883d3c40aa9e3927d07de1079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAttendButtonSelectTriggerButton_event$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonButton_event">;
  readonly " $fragmentType": "EventAttendButtonSelectTriggerButton_event";
};
export type EventAttendButtonSelectTriggerButton_event$key = {
  readonly " $data"?: EventAttendButtonSelectTriggerButton_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonSelectTriggerButton_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAttendButtonSelectTriggerButton_event",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonButton_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "46b0be316fb0db928ec52465c6cf5a83";

export default node;
